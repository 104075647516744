.messageTextWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user_emoji {
  align-self: flex-end;
}
.user_image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}
.message_text_logo_img {
  width: 50px;
  align-self: flex-end;
}

.message_content {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  max-width: 86%;
}

.message_text_content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  background: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.21),
    rgb(64 70 221 / 17%),
    rgba(174, 174, 174, 0.21)
  );
  font-size: 12px;
  color: #1e1e1e;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  flex: 1;
}
.my_text_content {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0px;
}
.message_time {
  position: absolute;
  right: 0;
  top: -12px;
  color: #aeaeae;
  font-size: 8px;
  align-self: flex-end;
}
.hover_options {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.options_icon,
.speakerIcon {
  color: grey;
}
.isspeaking {
  color: var(--primary_color_dark) !important;
}
.myoption {
  left: -3rem !important;
}
.speakerIcon {
  rotate: 180deg;
}

.otheroption {
  right: -3rem;
  flex-direction: row-reverse;
}

.message_user_box{
  display: flex;
  align-items: center;
  gap: 5px;
}
.message_user_name{
  font-size: 10px;
  letter-spacing: 1px;
}
.message_text{
  font-size: 13px;
  letter-spacing: 1px ;
}