.LoaderWrapper {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  backdrop-filter: blur(6px);
  height: 100vh;
  display: grid;
  place-items: center;
  pointer-events: none !important;
}
.content_loader {
  border: 7px solid rgb(64 70 221 / 47%);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  overflow: hidden;
}
.loader_plate {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 120%;
  width: 50%;
  background-color: rgb(64 70 221);
  animation: loader 1s ease-in-out infinite;
}
.image_wrapper {
  position: relative;
  border-radius: 50%;
  background-color: whitesmoke;
  height: 92%;
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image_wrapper img {
  width: 85px;
  height: 85px;
  object-fit: cover;
  border-radius: 50%;
}

@keyframes loader {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
