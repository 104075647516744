.subscription_container{
  /* background-color:   ; */
    background-color: #F5F7FA;
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    padding: 3rem 1rem;
    border-radius: 10px;
    margin-top: 1rem;

}
.subscription_header_box{
  display: flex;
  align-items: center;
  gap: 10px;
}
.subscription_header_box img{
  width: 45px;
}
.subscription_container h1{
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: var(--dark_primary_text_color);
    text-transform: uppercase;
    letter-spacing: 1px;
}
.subscription_container .pricing {
    display: flex;
    flex-wrap: wrap; 
    width: 100%;
    justify-content: center;
    

  }
  .plan_main_content_box{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-content: space-between ;
  }
  .subscription_container .pricing-plan {
    background-color: #ffffff;
    width: 28%;
    min-width: 250px;
    margin: 15px 8px;
    padding: 30px;
    border-radius: 10px;
    color:var(--dark_primary_text_color); 
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    cursor: pointer;
    transition: all .3s ease;
  
            

             
  }
  .subscription_container .pricing-plan:hover{
    scale: 1.03;
  }

.subscription_container .pricing-plan h2 {
    margin-top: 0;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--dark_primary_text_color);
    text-transform: uppercase;
    letter-spacing: 0.07em;
  }
.subscription_container  .pricing-plan p {
    margin-top: 0;
  }
.subscription_container .pricing-plan p span {
    font-size: 33px;
  }
  .subscription_container .pricing-plan ul {
    padding-left: 16px;
    list-style: none;
    margin-bottom: 20px !important;
    line-height: 1.8;
    color: #b5b5b5;

  }
  .subscription_container .pricing-plan ul li{
    font-size: 14px;
    color: var(--dark_primary_text_color);
  }
  .subscription_container .pricing-plan ul li::before {
    content: "•";
    color:  var(--dark_primary_text_color) ;
    display: inline-block;
    width: 20px;
    margin-left: -15px;
    position: relative;
    top: 4px;
    font-size: 26px;
    line-height: 0;
  }
  .subscription_container .pricing-plan button {
    text-decoration: none;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    background: var(--dark_primary_text_color);
    
    border-radius: 5px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 15px;
    /* font-weight: bold; */
    cursor: pointer;
  }
  .plan_name{
    color: var(--dark_primary_text_color);
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 16px;
  }
  .subscription_container .pricing-plan button:hover {
    background-color: #1f262c;
  
  }
  .termsAndConditionButtons{
    height: 50px;
    border-radius: 4px;
    font-size: 15px;
    letter-spacing: 1px;
    background-color: var(--dark_primary_text_color);
    color: white;
    padding: 0 2rem;
    margin:  auto;
    transition: all .3s ease;
  } 
  .termsAndConditionButtons:hover{
    scale: 1.03;
  }

  
  @media (max-width: 768px) {
    .subscription_container .pricing {
      display: block;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 992px) {

    .subscription_container .pricing-plan ul li{
      font-size: 14px;
    }
    .subscription_container .pricing-plan button {
     
      padding: 8px 14px;
      
      font-size: 13px;
      font-weight: 600;
  }
  }
  @media only screen and (min-width: 600px)  and (max-width: 769px){
    .subscription_container .pricing-plan {
      margin: 15px 0px;
      padding: 2rem;
    width: 100%;
  }
  .subscription_container .pricing-plan p span {
    font-size: 30px;
}
  .subscription_container .pricing-plan ul li{
    font-size: 13px;
  }
  .subscription_container .pricing-plan button {
   
    padding: 8px 14px;
    
    font-size: 12px;
    font-weight: 600;
}
  }
  @media only screen and (min-width: 480px) and (max-width: 600px) {
    .subscription_container .pricing {
      width: 100% !important;

  }
  .subscription_container .pricing-plan {
    padding: 1rem;
    width: 100%;
}
.subscription_container .pricing-plan ul li{
  font-size: 12px;
}
.subscription_container .pricing-plan a {
   
  
  font-size: 11px;
  font-weight: 600;
}
.subscription_container  .pricing-plan p {

  font-size: 12px;
}
.subscription_container .pricing-plan p span {
  font-size: 29px;
}
  }
  @media only screen and (max-width: 480px){
    .subscription_container .pricing {
      width: 100%;
      background-color: red;;
  }
    .subscription_container .pricing-plan {
      padding: 1rem;
  }
  .subscription_container .pricing-plan ul li{
    font-size: 11px;
  }
  .subscription_container .pricing-plan a {
     
    
    font-size: 10px;
    font-weight: 600;
    padding: 8px 10px;
  }
  .subscription_container  .pricing-plan p {
  
    font-size: 11px;
  }
  .subscription_container .pricing-plan p span {
    font-size: 27px;
  }
  }