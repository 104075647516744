.participantsWrapper {
  background: linear-gradient(
    45deg,
    rgb(174 174 174 / 5%),
    rgb(64 70 221 / 6%),
    rgba(174, 174, 174, 0.21)
  );
  backdrop-filter: blur(45px);
  flex: 3;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  border-radius: 10px;
}
.participatants_header {
  background: linear-gradient(
    45deg,
    rgb(174 174 174 / 5%),
    rgb(64 70 221 / 6%),
    rgba(174, 174, 174, 0.21)
  );
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;

  padding: 10px;
  border-radius: 8px;
}
.participatants_header p {
  font-size: 14px;
  letter-spacing: 1px;
  color: rgb(14, 11, 61);
  text-transform: uppercase;
  font-weight: bold;
}
.participants_other {
  width: 40px;
  height: 40px;
  background-color: #aeaeae;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 7px;
  color: white;
  border: 3px solid rgb(64, 70, 221);
  transform: translateX(-24px);
}
.participants_text {
  letter-spacing: 1px;
  text-transform: capitalize;
  color: rgb(64, 70, 221);
  font-weight: 700;
  font-size: 14px;
}
.avatars {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
/* img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid  rgb(64, 70, 221);;
            object-fit: cover;
        :nth-of-type(2){
            transform: translateX(-14px);
        }
        :nth-of-type(3){
            transform: translateX(-28px);
        }
            } */

.participation_person_list {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
