.youthCoachContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
    max-width: 1024px;
    padding: 2rem;
    align-items: center;
    margin: auto;
}
.youthHeaderText{
    font-weight: bold;
    color: var(--dark_primary_text_color);
    font-size: 2.1rem;
}
.youthCoachWrapper{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}