.Coaches{
    display: flex;
    gap: 2rem;
    background-color: #f4f7fa;
    padding: 2rem 6rem ;
    border-radius: 10px;
    flex-direction: column;
    align-content: center;
}
.coachContent{
    background-color: #ffffff;
    border-radius: 18px;
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;;
}
.coachHeader{
    display: flex;
    gap: 1rem;
    width: 100%;
    border-radius: 9px;
    /* background-color: #eff0f1;; */
    /* height: 80px; */
    padding: 0 1rem;
    align-items: center;

}
.coachHeader img{
    width: 50px;
    height: 50px;
    object-position: top;
}
.coachHeader h1{
    font-weight: bold;
    color: var(--dark_primary_text_color);
    font-size: 2.1rem;
}
.coachDesc{
       font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1px;
    color: gray;
    word-break: break-all;
}
.coaches_header_top{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    border-radius: 18px;
    padding:  1.4rem 2rem;

}
.coaches_heading_box img{
    width: 42px;
}
.coaches_heading_box h1{
    font-size: 1.3rem;
    letter-spacing: 1px;
    color: var(--dark_primary_text_color);
    margin-bottom:  0 !important;
    font-weight: bold;
    
}
.coaches_heading_box{
    background-color: #eff0f1;;
    height: 50px;
    width: fit-content;


gap: 10px;
    display: flex;

    align-items: center;


    padding : 0 1rem ;
    border-radius: 10px;
}
.coachContentWrapper{
    align-items: center;
    max-width: 1024px;
    display: flex;
    width: 95%;
    flex-direction: column;
    gap: 2rem;
    margin: auto;
}
.coaches_header_top p{
    text-align: center;
    width: 95%;
    color: var(--dark_primary_text_color);
    font-size: 15px;
    line-height: 25px;
    color: gray;
    letter-spacing: 1px;

}
.coach_container{
    flex: 1;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 2rem;  
    margin: auto;
}
.coach_box{
    flex: 1;
    min-width: 200px ;
    max-width: 300px;
    margin: auto;
    display: flex;
     height:fit-content;
    align-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 18px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding:  1rem;
    gap: 2rem;    
    cursor: pointer;    
    transition: all .3s ease;
}

.coach_box:hover{
    scale: 1.03 ;
}

.coach_box img{
    width: 65%;        
    /* height: 100%; */
    /* border-radius: 5px; */
    object-fit: contain;
}
.coach_img_wrapper{
    height: 270px;
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: #8080800f; */
    border-radius: 9px;
}
.coach_img_wrapper img{
    width: 100% !important;
    object-fit: cover !important;
    object-position: top;
    height: 100%;
    border-radius: 5px;
}
.coach_desc{
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    color: #777777;
    line-height: 20px;
    display: inline;
    display: flex;
    text-align: justify;
       display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis; 
}
.coachFlag{
    display: inline;
    width: 20px  !important;
}
.coach_desc b{
    color: var(--dark_primary_text_color);
    text-transform: uppercase   ;
    font-size: 14px;
}
.top_header_text{
    font-weight: bold;
    font-size: 2rem;
}
.coach_hire_button{
    background-color: var(--dark_primary_text_color);
    color: white;;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;
 
    /* width: 40%; */
    
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    border-radius: 3px;
    height:40px;
    color: white;
    padding:  0 1rem;
    /* width: 100%; */
}
.coach_hire_button svg{
    font-size: 15px !important 
        
    
}
.coach_country_flag{
    width: 24px !important;

}
.coach_country_box{
    display: flex;
    color: gray;
    align-items: center;
    gap: 8px;
    background-color: var(--dark_primary_text_color);
    flex: 1;
    color: white;
    border-radius: 5px;
    font-size: 13px ;
    letter-spacing: 1px;
    justify-content: center;
    padding: 4px 10px;
    flex-direction: row-reverse;
     /* display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;  */
}
.coach_bottom_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.coachItemBottom{
    width: 100%;
    display: flex;
    gap: 0.5rem;
}

/* media queries */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .coaches_heading_box img{
        width: 36px;
    }
    .coaches_heading_box h1{
        font-size: 1rem;
    }
    .coaches_header_top p {
        font-size: 13px;
        line-height: 23px;
    }
    .coach_container {
        width: 85%;
    }
    .coach_box{
        width: 320px;
        /* height: 330px; */
    }
}
@media only screen and (min-width: 600px)  and (max-width: 769px){
    .Coaches{
        padding: 2rem !important;
    }
    .coaches_heading_box img{
        width: 30px;
    }
    .coaches_heading_box h1{
        font-size: 0.96rem;
    }
    .coaches_header_top p {
        font-size: 11px;
        line-height: 22px;
    }
    .coach_box{
        /* width: 370px; */
        /* height: 365px; */

    }
}
@media only screen and (min-width: 480px) and (max-width: 600px) {
    .Coaches{
        padding: 2rem !important;
    }
    .coaches_heading_box img{
        width: 30px;
    }
    .coaches_heading_box h1{
        font-size: 0.9rem;
    }
    .coaches_header_top p {
        font-size: 11px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 480px){

    .coaches_header_top{
        width: 80%;
        padding: 1.4rem 1rem;
    }
    .coaches_heading_box img{
        width: 30px;
    }
    .coaches_heading_box h1{
        font-size: 0.84rem;
    }
    .coaches_header_top p {
        font-size: 11px;
        line-height: 21px;
    }
    .coach_box img{
        width: 100%;
    }
}