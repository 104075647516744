.live_debate_box{
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.live_debate_heading{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.live_debate_sub_heading{
    font-size: 12px;
letter-spacing: 1PX;
    color: var(--dark_primary_text_color);
}
.live_debate_heading_text{
    margin: 0 !important;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    font-weight: bold;
    color: var(--dark_primary_text_color);
}
.live_debate_heading_text img{
    width: 30px;
    height: 30px;

}
.liveDebateList{
    display: flex;
    flex-direction: column;
    gap: 5px;
max-height: 256px;
    border-radius: 7px;
    overflow-y:auto;
  }

.liveDebateItem{
    justify-content: space-between;
    display: flex;
    /* min-height: 50px; */
    height: 60px;
    margin-bottom: 5px;
    width: 100%;
    background-color: aliceblue;
    padding: 0.5rem 0.5rem ;
    border-radius: 8px;
    cursor: pointer;
    gap: 4px;
    transition: all .3s ease-in-out;
}
.join_button{
display: flex;
background-color: rgb(44, 156, 255) ;
color: white;
font-size: 12px;
letter-spacing: 1px;
border-radius: 4px;
gap: 5px;
padding: 7px 14px;
align-items: center;
height: 30px;

}
.join_button img{
    width: 20px;
    display: none;
}
.liveDebateItem h5{
    /* color: rgb(44, 156, 255) ; */
    font-size: 12px;
    
    line-height: 19px;
}
.debateTopic{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
    
   -webkit-box-orient: vertical;
}
.started_time_ago{
    font-size: 9px;
    color:rgb(118, 117, 117);   
    letter-spacing: 1px;
    margin-top: -2px;
}
.joinBtnLink{
    align-self: center;
}

.no_live_debates_box{
    display: flex;
    align-items: center;
    flex-direction: column;

}
.no_live_debates_box p{
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.8px;
    color: gray
    

}
.noLiveDebatesImage{
    
    margin:auto;
    width: 70%;
    object-fit: cover;

}
.live_debate_debates_wrapper{
    height: 270px;
    overflow-y: scroll;
    
}
/* @media only screen and (min-width: 769px) and (max-width: 992px) {} */
@media only screen and (min-width: 600px)  and (max-width: 769px){

    .noLiveDebatesImage{
    
    margin:auto;
    width: 50%;
    object-fit: cover;

}
    .liveDebateList{
        
        min-height: 200px;
        align-items: center;
    }
    .liveDebateItem {
      
        width: 85%;
        
    }
   
   
}
@media only screen and (min-width: 480px) and (max-width: 600px){
    .noLiveDebatesImage{
    
    margin:auto;
    width: 50%;
    object-fit: cover;

}
    .liveDebateList {
        
        flex-direction: column;

    height: 205px;
}
    }

    @media only screen and (max-width: 480px){
        .liveDebateItem{
            padding: 8px;
        }
.noLiveDebatesImage{
    
    margin:auto;
    width: 50%;
    object-fit: cover;

}
        .liveDebateList {
            height: 140px;
                    
        }
        .live_debate_heading_text {
            font-size: 1rem;
        }
        .live_debate_sub_heading{
            font-size: 10px;
        }
    }
    
