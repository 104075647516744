 .searchUserBox{
    
    background:#ffffff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;;
    padding: 1rem;;

 }



.searchUserContainer {
    /* display: flex;
    max-height: 300px;
    min-height: 300px;;
    overflow: scroll;
    flex-direction: column; */
}

