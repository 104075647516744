.maintainanceHeaderBox{
    height: 70px;
        width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

}
.maintainanceHeaderText{
    font-weight: bold;
        letter-spacing: 1px;
        font-size: 1.4rem;
        color: var(--dark_primary_text_color);
}
.headerButton{
    background-color: var(--dark_primary_text_color);
        color: white;
        font-size: 14px;
        letter-spacing: 1px;
        padding: 8px;
        border-radius: 3px;
}
.maintainanceMainText{

    padding: 1.5rem;
        background-color: rgba(128, 128, 128, 0.117);
        border-radius: 10px;
        letter-spacing: 1px;
        border: 1px solid rgba(192, 192, 192, 0.441);
}
.updateBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    ;
    height: 0px;
    overflow: hidden;
    transition: all .3s ease;
    margin-top: 1rem;
}

.updateMode {
    height: 400px !important;
}

.updateBox textarea {
    padding: 1rem;
    width: 100%;
    letter-spacing: 1px;
    border-radius: 7px;
    background-color: rgb(229, 229, 242);
    /* min-height: 100px; */
    /* height: 400px;
    /* height: fit-content; */
    height: 100%;
}

.updateButton {
    height: 50px;
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: var(--dark_primary_text_color);
}