.DebateScreenBoxWrapper {
  width: 100%;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
}
.box_wrappers {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-height: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  min-width: 350px;
}
.pink_wrapper {
 
}
.team_name_debate_screen {
  letter-spacing: 0.8px;
  font-size: 20px;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0.7;
  width: fit-content;
  /* background:var(--dark_gradient_bg); */
  padding: 2px ;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 35px;
 color: var(--dark_primary_text_color);
 
  font-size: 19px;
}

.screen_box_header {
  height: 50px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  
  
}
.screen_box_header img{
  width: 34px;

}
.speakTime_left_box_time_show{
  display: flex;

  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}
.speakTime_left_box_time_show h4{
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1px;
}
.screen_box_header .team_mic_icon{
  font-size: 1.2rem !important;
}


.teamTwo::after {
  background: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.11),
    rgba(174, 174, 174, 0.21),
    #de009b,
    #de009be8
  );
}
.left_team,
.right_team {
  flex: 6;
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  height:calc(100% - 80px);
}
.no_one_joinedtext {
  text-align: center;
  letter-spacing: 1px;
  color: rgba(128, 128, 128, 0.795);
}
.mic_control_wrapper{
  background: var(--dark_primary_text_color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: grid;
  place-items: center;

  
}
.mic_control_wrapper svg{
  color: white !important;
}
.screen_box_team_name_box{
  display: flex;
  align-items: center;
  gap: 5px;
}
.screen_box_vote_button{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 35px;
    border-radius: 4px;
    background: var(--primary_color);
    background-color: white;

    padding: 0 10px;
    border: 3px solid var(--primary_color);
  
} 
.screen_box_vote_button p{
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--primary_color);


}
.vote_img{
  width: 20px !important;
}
.disable_vote_button{
  /* display: none !important; */
  pointer-events: none;
  opacity: 0.7;
}
.active_screen{
  box-shadow: rgb(64, 70, 221) 0px 0px 4px 1px !important;
}
@media screen   and (min-width:600px) and (max-width:768px){

  .DebateScreenBoxWrapper{
    flex-direction: column;
  }
  
}

@media screen   and (min-width:480px) and (max-width:600px){

  .DebateScreenBoxWrapper{
    flex-direction: column;
  }
  
}
@media screen  and (max-width:480px){
  .DebateScreenBoxWrapper{
    flex-direction: column;
  }
  

  
}