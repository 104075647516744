.home_wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 5rem !important;
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.home_bottom_info{
  max-width: 1200px;
  margin-top: 5rem;
  width: 95%;
  margin: auto;
  margin-top: 5rem;
  display: flex;
  gap: 1rem;
}


@media only screen and (min-width: 600px)  and (max-width: 769px){
  .home_bottom_info {

    margin-top: 3rem;
    flex-direction: column;
   
}
}

@media only screen and (min-width: 480px) and (max-width: 600px){
  .home_bottom_info {
    flex-direction: column;
    margin-top: 1rem;
}
}

@media only screen and (max-width: 480px){

  .home_bottom_info {
    
    flex-direction: column;
    margin-top: 2rem;
    
  }
}