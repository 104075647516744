.ChatbotWrapper {
  height: calc(100vh - 80px);
  display: flex;
  justify-content: space-between;
  padding: 0px 0;
  align-items: center;
  gap: 2rem;
  padding:1.5rem;
  padding-bottom: 10px;

}
.starter_bot_img {
  width: 200px;
}
.chat_box_message {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
  padding-bottom: 2rem;
  height: 100%;
  flex: 1;

}
.chat_bot_message_play_ground {
  border-radius: 10px;
  /* background: var(--dark_gradient_bg); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 0 1rem;
  flex: 9;
}

.chatbot_bg_bot{
  position: absolute;
  left:0 ;
  right: 0;
  top: 0;
  bottom: 0;
  margin:auto;
  opacity: 0.5;
  width: 30%;
  animation: chatBotAnimate  3s ease-in-out infinite alternate-reverse ;
}

@keyframes chatBotAnimate {

  0%{
    scale: 1;
    transform: translateY(-10px);
    opacity: 0.4;
  }
  100%{
    transform:translateY(10px);
    scale: 1.03 ;
    opacity: 0.6;
  }
  
}