.top_debators{
    width: fit-content;
    margin:  0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    flex: 8;

}
.top_debators_heading{

    text-align: center;
}
.top_users_list{
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 2rem;

}
.top_debators_main_text{
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    margin-bottom: 0 !important;
    font-size: 1.4rem;
    color: var(--dark_primary_text_color);
    font-weight: bold;

    gap: 0.3rem;
}
.top_debators_main_text img{
    width: 30px;
    height: 30px;
}
.top_deb_sub_heading{
    font-size: 12px;
    margin-top: -0.5rem;
    color:  rgb(14, 11, 61);;
    letter-spacing: 1px;
}
.list_item{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px;
}
@media only screen and (min-width: 769px) and (max-width: 992px) {

    .top_users_list {
     
        flex-direction: column;
        
    }
    .top_debators_main_text{
  justify-content: center;
}
}

@media only screen and (min-width: 600px)  and (max-width: 769px){

    .top_debators_main_text{
        
        justify-content: center;
        font-size: 1.3rem;
        
    }
    .top_users_list{
      
        gap: 1.5rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 600px){
    .top_users_list{
        flex-direction: column;
    }
    .top_debators_main_text{
       
        justify-content: center;
        font-size: 1rem;
        
    }
}


@media only screen and (max-width: 480px){

    .top_users_list {
        flex-direction: column;
        
    }
    .top_debators_main_text{
       
        justify-content: center;
        font-size: 1rem;
        
    }
    .top_deb_sub_heading {
        font-size: 10px;
        margin-top: 1rem;
    }
}