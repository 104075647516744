.termsAndConditionHeader{
    text-align: center;
        /* width: 700px; */
        display: flex;
        justify-content: center;

}
.termsAndConditionHeader h1{
    font-size: 1.5rem;
    letter-spacing: 1px;
    color: var(--dark_primary_text_color);
    font-weight: bold;
}
.terms_paragraphs{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.terms_text{
    color: gray;
    font-size: 14px;
    letter-spacing: 1px;
    
    /* text-align: center; */
    /* text-align: justify; */
    word-break: break-all;
    font-weight: 100;
    line-height: 25px;
}
.termsAgreeButton{
    /* background-color:whitesmoke; */
    background-color: var(--dark_primary_text_color);
    color: white;
    border: 3px solid var(--dark_primary_text_color);
    height: 40px;
    width: fit-content;
    margin: 0 auto;
    padding: 0 1rem;
    letter-spacing: 1px;
    border-radius: 4px;

}
.TermsAndConditions{
    padding: 1rem;
    padding-top: 0;
}