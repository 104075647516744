.chatBotWrapper{
    width:100%;
    height: 100%;
    animation: chatBotAnimate  3s ease-in-out infinite alternate-reverse ;
    display:flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    
    
}
.chatBotWrapper img{
    width: 22%;
}
.main_text_chat_bot{
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 100;
    color:var(--primary_dark_text_color);
    font-weight: bold;
    
    line-height: 40px;
    letter-spacing: 2px;
      font-family: 'Righteous', cursive;

}
.secondary_text_chat_bot{
    font-size: 11px;
    color: gray;
    letter-spacing: 1px;
}
.chat_bot_content{
    text-align: center;
    margin-bottom: -55px;

}
.chatBotImages{
    display: flex;
    justify-content: center;

}


@keyframes chatBotAnimate {

  0%{
    scale: 1;
    transform: translateY(-4px);
    filter: blur(1px );

  }
  100%{
    transform:translateY(20px);
    scale: 1.03 ;
    filter: blur(0.6px);
  }
  
}