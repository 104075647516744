.accordian_item{
 background-color: rgb(249 248 248 / 88%) !important;
}
.comment_count_text{
    font-size: 12px;
    width: fit-content;
}
.comment_container{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
    
}
.motion_topic{

    font-size: 13px;
    text-align: start !important;
}
.topic_box{
    display: flex;
    justify-content: space-between;   
    flex-direction: column;
    gap: 5px;
}
.topic_box p:nth-of-type(2){
    /* background-color: red; */

    /* width: 200px; */
    
}
.new_comment_box{
    width: 100%;
height: 40px;
border-radius: 6px;
overflow: hidden;
background-color:#ebebeb ;
padding: 0 10px;
font-size: 13px ;
}
.new_comment_box::placeholder{
    font-size: 12px;
    letter-spacing: 1px;
}
.new_comment_box input{
    background-color: transparent;
    width: 100%;
    height: 100%;
    
    border: none;
    outline: none;
}
.delIcon{

    fill: rgb(255, 255, 255);
   font-size: 10px;;
}
.delBox{
        position: absolute;
        display: grid;
        place-items: center;
        width: 20px;
        height: 20px;;
        background-color: rgba(255, 0, 0, 0.495);
        border-radius: 50%;
            top: -0.51rem;
            left: 0;
}
.delBox:hover{
    background-color: red;
}