.CoachDecision{
    padding:2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;;
    align-items: center;
}
.decision_header{
    display: flex;
    flex-direction: column;
    gap: 1rem;;
    align-items: center;
    width: 100%;
}
.top_header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
}
.top_header img{
    width: 38px;
    height: 38px;
}
.loadingSvg{
    font-size: 1rem;
    width: 40px;
    animation: spinner 0.5s ease-in-out infinite;
}
.main_text{
    font-size: 2rem;
    font-weight: 600;
    color: var(--dark_primary_text_color);
}
.desc_text{
    color: var(--light_primary_text_color);
    letter-spacing: 1px;;
    font-size: 12px;
}

.decision_bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;;
}

.teamWrapper{
    display: flex;
    gap: 1rem;

}
.teamBox{
    width: 90px;
    height: 80px;
    border-radius: 8px;;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;;
    cursor: pointer;
    border: 1px solid rgb(214, 214, 214);
    transition: all .3s ease;
    padding: 0.5rem;;
}
.teamBox:hover{
    scale: 1.05;
}
.teamBox img{
    width: 30px;
    height: 30px;
}
.teamName{
    font-weight: 500;
    font-size: 9px;;
    color: rgb(90, 90, 90);
    letter-spacing: 1px;;
    word-break: break-all;
    text-align: center;
}
.bottom_text{
    font-size: 10px;;
    color: rgb(124, 124, 124);
    /* font-weight: bold; */
    letter-spacing: 1px;

}
.activeTeamBox{
    scale: 1.05 !important;
    border: 2px solid var(--dark_primary_text_color);
}
.activeTeamBox .teamName{
    color: var(--dark_primary_text_color);
}
.decisionButton{
    background-color: var(--dark_primary_text_color);
    height: 40px;
    padding: 0 1rem;;
    width: fit-content;
    font-size: 12px;
    letter-spacing: 1px;
    color: white;
    border-radius: 5px;;
    font-weight: 500;
    opacity: 0.7;
    pointer-events: none;
}
.activeButton{
opacity: 1 !important;
pointer-events:initial !important;
}
.winnerBox{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.winnerBox img{

    /* width: 60px;
    height: 60px; */


}
.winText{
    font-size: 2rem;;
    font-weight:600 ;
    text-transform: uppercase;
    color: var(--dark_primary_text_color);
}
.winnerName{
    display: inline-block;
    font-weight: bold !important;
    color: var(--primary_color) !important;
}
.winnerTeamComp{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}
.claimButton{
    height: 45px;
    padding: 0 1rem;;
    border-radius: 4px;;
    background-color: var(--dark_primary_text_color);
    color: white;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 12px;

}
@keyframes spinner {

    0%{
        rotate: 0deg;

    }
    100%{
        rotate: 100deg;
    }
    
}