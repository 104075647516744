.motion_page{
    width: 100%;

}
.motion_container{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.motion_header{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 60%;
    margin: 0  auto;

}
.motion_header_text{
    font-weight: bold   ;
    text-align: center;
    font-size: 2rem;
    letter-spacing: 1px;

}
.motion_header_desc{
    text-align: center  ;
    font-weight: 100;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.671);

    font-size: 14px;

    width: 100%;
    letter-spacing: 1px;
}

/* motion cat box */
.motion_category_wrapper{
    display: flex;
    gap: 1rem;
    padding: 2rem;
    justify-content: center;
    align-items: center;
}
.motion_cat_box{
    position: relative;
    min-width: 90px;
    /* height: 90px; */
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: grid;
    place-items: center;
    cursor: pointer;
    padding: 10px;
    transition: all .3s ease-in-out;
    opacity: 0.8;
    background-color: rgba(200, 200, 200, 0.171);
    
}
.motion_add{
    /* width: 80px;
    height: 80px; */
}
.motion_cat_box:hover{
    scale: 1.04;
}

.motion_cat_box img{
width: 60%;
}
.motion_cat_box p{
    font-size: 12px;
    text-align: center;
    color: gray;
    letter-spacing: 1px;
    text-transform: capitalize;
}
/* motion button contaienr */

.motion_button_container{
    display: flex;
    align-items: center;
    height: 50px;
    width: 30%;
    background-color: rgba(255, 255, 255, 0.904)  ; 
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
    border: 2px solid var(--dark_primary_text_color);
    padding:2px;
}
.button_tab{
    width: 50%;
    letter-spacing: 1px;
    font-size: 13px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 5px;
    outline: none;
}
.button_tab img{
    width: 25px;

}
.button_tab p{
    color: var(--dark_primary_text_color) !important;

}

.active_tab{

    border-radius: 2px;
    background-color: var(--dark_primary_text_color);
    
}
.active_tab p{
    color: white !important;
}
.active_motion_cat{
    background-color: var(--dark_primary_text_color);
    opacity: 1;
    scale: 1.1;
    border: 2px solid var(--dark_primary_text_color);
}
.active_motion_cat p{
    color: white !important;

}
.delete_motion_icon{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.6rem;
    color: red;
    opacity: 0.7;
    display: none;
}
.showDelIcon{
    display: block !important;
}