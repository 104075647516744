.ParticipantsPersonWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
  padding: 0.41rem  10px;
  background:#fafafaa1;
  backdrop-filter: blur(45px);
}
.ParticipationPersonWrapper:hover {
  background: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.21),
    rgb(64 70 221 / 12%)
  );
}
.participation_person_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.participation_person_box{
  display: flex;
  gap: 8px;
  align-items: center;
}
.participation_person_box p{
  text-transform: uppercase;
  color: rgb(91, 90, 90);
  letter-spacing: 1px;
  font-size: 14px;
}