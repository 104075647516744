.teamFormWrapper {
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.teamFormWrapper h2 {
  text-transform: uppercase;
  font-weight: 400;
 letter-spacing: normal;
 
  margin-bottom: 10px;
  color:gray
  ;
}
.team_form_input_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.search_team_name_input,
.addMember_input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border: none;
  outline: none;
  font-size: 14px;
  height: 100%;
  padding-right: 10px;
  
  border-radius: 10px;
  color: rgb(153 148 148);;
  background-color: transparent;
}
.search_team_name_input::placeholder,
.addMember_input::placeholder {
  font-size: 12px;
  color: rgba(128, 128, 128, 0.705);
}
.search_user_input{
display: flex;
  position: relative;
  align-items: center;

}
.search_user_input svg{
  font-size: 12px ;
}
.searchUserSvgClose {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 8px;
  font-size: 10px;
  cursor: pointer;


}
.team_info_text{
  text-align: center;
}
.team_info_text span{
  color: rgba(128, 128, 128, 0.479) !important;
}