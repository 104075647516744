.finishModalContainer{
    width: 100% !important;
    height: 100%;
 

}
 .main_bg_image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.modalBodyContent{
    position:absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,#111212,#130f40);
    color: white;
    
    display: flex;
    justify-content: center;
    align-items: center;

}



