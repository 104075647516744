.speak_time_left_box{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    padding: 10px ;
    position: relative;
    background-color: white;
}
.speak_time_debate_state{
    position: absolute;

    top: 15px;
    right: 10px;
    color: white;
    font-size: 12px ;
    border-radius: 4px;
    background: var(--primary_color);
    letter-spacing: 1px;
    height: 30px;

    width: fit-content;
    padding: 0 10px;
    padding-top: 5px;
    font-size: 12px;
}
.speak_time_info{
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}
.speak_time_left{
    width: fit-content;
    padding: 0 10px;
    background-color: var(--dark_primary_text_color);
    color: white !important;
    height: 23px !important;
    padding-top: 5px;
    font-weight: 100 !important ;
    letter-spacing: 1px;
    border-radius: 4px;
}
.speak_time_value ,.speak_time_key{
    text-transform: uppercase !important;
}

.speakTime_title svg{
    font-size: 22px !important;
    margin-right: 10px;
}
.speakTime_item,.speakTime_title{
    gap: 5px;
    font-size: 14px;

    font-weight: 700;
    letter-spacing: 1px;


}
.speakTime_title img{
    width: 30px;
}
.speakTime_item p{
    padding-right: 20px;    
    
    color: rgb(73, 73, 73);
    font-weight: 200; 
    margin-bottom: 0 !important;

}


.speakTime_item{
    display: flex;
    margin-bottom: 10px;
}
.speakTime_title{
    display: flex;
    align-items: center;
}
.speakTime_title svg{
    color: var(--dark_primary_text_color);
    font-size: 1rem;
}

.speakTime_item>p{
    line-height: 30px;
}
.speakTime_title p{
    color: rgb(73, 73, 73);
    font-weight: 200;
}
.speak_time_value{
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.speak_time_right{
    right: 0 !important;

}
.speak_time_key{

}
.durationTimeWrapper{
    display: flex;
    gap: 1px;
    align-items: center;
}
.durationTimeWrapper p{
    padding-right: 10px !important;
}

@media screen  and (min-width:768px)and (max-width:992px){


    .speak_time_info{
        grid-template-columns: 1fr;

    }
    
}
@media screen  and (min-width:600px)and (max-width:768px){


    .speak_time_info{
        grid-template-columns: 1fr;
        
    }
    
}

@media screen  and (min-width:480px)and (max-width:600px){


    .speak_time_info{
        grid-template-columns: 1fr;
        
    }
    
}
@media screen  and  (max-width:480px){


    .speak_time_info{
        grid-template-columns: 1fr;
        
    }
    
}