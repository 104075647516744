.debatorViewWrapper {
  flex: 1;
  min-width: 200px;
  /* padding: 1rem ; */
  display: flex;
  height: 200px;
  max-height: 250px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  background:linear-gradient( 45deg, rgb(236 237 252 / 62%), rgb(84 90 250 / 3%) );
  backdrop-filter: blur(45px);
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.4s;
}
.pink {
  background: var(--secondary_gradient_bg) !important;
}
.isSpeaking {
  background: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.21),
    rgb(64 70 221 / 24%),
    rgb(174 174 174 / 28%)
  ) !important;
  scale: 1.03;
  border: 3px solid var(--primary_color) !important;
}


.participant_video {
  width: 100%;
  height: 80%;
}

.participant_video :hover {
  scale: 1.03;

  /* linear-gradient(45deg, rgba(174, 174, 174, 0.21), rgb(64 70 221 / 17%),rgba(174, 174, 174, 0.21));; */
  /* linear-gradient(45deg,#f709af29,rgb(183 183 195 / 31%),#f709af45),#f709af05; */
  background: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.21),
    rgb(64 70 221 / 24%),
    rgb(174 174 174 / 28%)
  );

  filter: blur(0px);
}
.debator_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid rgb(64 70 221 / 70%);
  padding: 5px;
  object-fit: cover;
}
.debator_name {
  font-size: 12px;
  color: rgba(64, 70, 221, 0.7);
  text-transform: uppercase;
  font-weight: 600;
}
.action_button_box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;

  padding: 0 10px;
}
.action_button_box svg {
  color: rgba(64, 70, 221, 0.7);
}
