.dashboard{
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 2rem;
 
}
.upper_item_box{
    flex-wrap: wrap;
    display: flex;
    gap: 2rem;
    width: 100%;

}