.BannerWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.4rem;
  align-items: center;
  position: relative;
  z-index: 2;
}
.robo{
  position: absolute;
  z-index: -1;
  width: 15%; 
}
.logo_second{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: auto;
  width: 80px;

}
.roboLeft{

  left: 0 ;
}
.roboRight{
  right: 0 !important;
}
.banner_bg_image {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: -1;
}
.banner_bg_image img {
  width: 150px;
  position: absolute;
  opacity: 0.5;
}
.speaker_img {
  top: 30%;
  width: 200px;
  opacity: 1;
}

.cartoon_speech {
  left: 1%;
  top: 20%;
  opacity: 0.31;
  width: 120px;
}
.hello_dinasour {
  right: 10%;
  width: 100px;
  top: 40%;
  opacity: 0.2;
}
.weeklyTopicText{
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.8rem;
  color: #18A0F8;
  letter-spacing: 1px;
}
.banner_content {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
  padding: 2rem;
  gap: 0.5rem;
  padding-bottom: 1rem ;
}
.top_site_info::after {
  content: "";
  width: 40%;
  position: absolute;
  height: 2px;
  background: rgb(104, 109, 224);
  border-radius: 30px;
  bottom: -7px;
  
  left: 0;
  right: 0;
  margin: auto;
}

.bottom_text {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.main_text_banner {
  text-align: left;
  font-size: 2rem;
  line-height: 47px;
  letter-spacing: 1px;
  font-weight: 700;
  word-break: keep-all;
  color: rgb(14, 11, 61);
  margin-bottom: 0rem !important;
  white-space: nowrap; 
}
@keyframes roll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
/*@keyframes roll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}*/
.top_site_info {
  font-weight: bold;
  color: rgb(64 70 221);
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  position: relative;
}
.secondary_main_banner_text {
  color: rgb(14, 11, 61);
  text-align: center;
  width: 60%;
  line-height: 23px;
  margin-top: 1rem;
  font-size: 14px;
  word-break: keep-all;
}
.quodium {
  display: inline;
  width: 40px;
}

.banne_bottom_option_box {
  text-align: center;

  display: flex;
  gap: 10px;
  font-size: 14px;
  letter-spacing: 1px;
  align-items: center;

  width: 50% !important;
  justify-content: center !important;
}
.banne_bottom_option_box p{
  margin: 0 !important;
}

.banne_bottom_option_box button svg {
  font-size: 1.3rem;
}

.prepare_for_debate_btn {
  display: flex;

  align-items: center;
  height: 50px;
  gap: 10px;
  padding: 0 1rem;
  background-color: white;
  border: 1px solid var(--dark_primary_text_color);
  font-size: 15px;
  justify-content: center;
  letter-spacing: 1px;
  border-radius: 3px;
  outline: none;
  color: var(--dark_primary_text_color);
  transition: all .3s ease;
  width: fit-content;

}
.prepare_for_debate_btn svg{
  font-size: 1.8rem !important;
}
.prepare_for_debate_btn:hover ,.banner_bottom_button_option_button:hover {
  scale: 1.05;

}

.debate_link_box {
  width: 37%;
  height: 50px;
  padding: 5px;

  display: flex;
  gap: 10px;
}
.debate_link_box input{
  font-size: 13px !important; 
}

.BannerWrapper input::placeholder {
  color: #a3a3a3;
  font-size: 12px;
}
.banner_bottom_button_option_button {
  background-color: var(--dark_primary_text_color);
  color: white;
  width: fit-content;
  letter-spacing: 1px;
  justify-content: center;
  outline: none;
  display: flex;
  transition: all .3s ease;
  font-size: 15px;
  height: 50px;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
  gap: 10px;
}
.banner_bottom_button_option_button svg {
  font-size: 1.8rem !important;
}
.watch_button {
  background-color: var(--primary_color);
  flex: 1;
  height: 100%;
  color: white;
  display: flex;
  border-radius: 4px;
  align-items: center;
  padding: 0 1rem !important;
  transform: skewX(350deg);
  gap: 10px;
}
.watch_button:hover {
  /* background-color: var(--primary_dark_color); */
}
.disable_watch_button_banner{
  opacity: 0.8;
}
.disabled_vote_button{

}


@media screen and (min-width:992px) and (max-width:1200px) {

.main_text_banner{
  font-size: 2rem !important;
}
.banner_content{
}  
.banne_bottom_option_box {
  width: 80% !important;
}
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .banner_content{
  }
  .main_text_banner {
    font-size: 25px;
    line-height: 38px;
    margin: 0;
}
.banne_bottom_option_box{
  width: 65% !important;
}
.secondary_main_banner_text {
  line-height: 22px;
  font-size: 12px;
}
.top_site_info {
  margin-bottom: 10px;
}
.banner_bottom_button_option_button {
  height: 40px;
  font-size: 12px;
}
.prepare_for_debate_btn {
  font-size: 12px;
  height: 40px;
}
.User_top_user__3yTkc {
  padding: 0.3rem 12px;
}

}
@media only screen and (min-width: 600px)  and (max-width: 769px){
  .banne_bottom_option_box{
    display: flex;
  }
  .banner_content {
    margin-bottom: -1rem;
}
  .main_text_banner {
    font-size: 18px !important;
    line-height: 25px !important;
}
.secondary_main_banner_text {
  line-height: 22px;
  margin-top: 12px;
  font-size: 13px;
}
.banner_bottom_button_option_button {

  height: 38px;
  padding: 0 8px;
  font-size: 12px;
}
.prepare_for_debate_btn {
  height: 38px;
  padding: 0 8px;
  width: 214px;
  font-size: 12px;
}
.top_site_info {
  margin-bottom: 1rem;
}
.main_text_banner {
  font-size: 25px;
  line-height: 35px;
}
.banne_bottom_option_box {
  width: 65% !important;
}



}
@media only screen and (min-width: 480px) and (max-width: 600px) {

  .banner_content{
    width: 100%;
    padding: 0 0.5rem;
    gap: 1rem;
  }

  .robo{
    display: none!important;
  }

  .main_text_banner {
    font-size: 20px;
    line-height: 27px;
}
.bottom_text{
  width: 80%;

}
.BannerWrapper {
  margin-bottom: 3rem;
}
.secondary_main_banner_text {
  font-size: 11px;
  line-height: 22px;
  margin: 0;
}
.banne_bottom_option_box {
  flex-direction: column !important;
  width: 70% !important;
  font-size: 11px;


}

.banner_bottom_button_option_button{
  justify-content: center !important;
  width: 300px !important;
}
.prepare_for_debate_btn {
  align-items: center;

  height: 45px;
  font-size: 14px;
  width: 300px !important;
}

}

@media only screen and (max-width: 480px){
  .banne_bottom_option_box{
    flex-direction: column !important;
    width: 70%;
  }
  .robo{
    display: none!important;
  }
  .banner_content {
    width: 100%;
    padding: 0rem !important;
    padding-bottom: 1rem;
    margin-top: 1rem;
}
.top_site_info {
  font-size: 11px;
}
.main_text_banner {
  font-size: 15px;
  line-height: 25px;
  
  font-weight: 600;
 
  margin-top: 1rem !important;
  padding: 0 1rem;
}
.bottom_text {
  width: 85%;
}
.secondary_main_banner_text {
width: 100%;
font-size: 10px;
}

.banner_bottom_button_option_button {
  height: 40px;
  font-size: 12px !important;
  justify-content: center;
  display: flex;
  width: 15rem;
  letter-spacing: 2px;
}
.banner_bottom_button_option_button svg{
  font-size: 1.4rem !important;
}
.prepare_for_debate_btn {
  height: 34px;
  font-size: 12px;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prepare_for_debate_btn svg{
  font-size: 1.4rem !important;
}
}