.upload_form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 500px;
 }
.upload_form input, .upload_form select{
  height: 40px;
  width: 100%;
  border-radius: 3px;
  font-size: 11px;
  padding: 0 10px;
  outline: none;
  letter-spacing: 1px;
  border: none;
  border: 1px solid rgba(128, 128, 128, 0.548);
  color: gray;
  border-radius: 6px;
  background-color: #f5f1f121;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.upload_form input::placeholder,.upload_form select::placeholder{
   color: gray;
   letter-spacing: 1px ;
}
.upload_btn{
   background-color: var(--dark_primary_text_color);
   width: fit-content;
   padding:  0 1rem;
   border-radius: 5px;
   margin: auto;
   height: 40px;
   color: white;
   letter-spacing: 1px;
   font-size: 14px;
   margin-top: 2rem;
}
.headerText{
   font-weight: bold;
   font-size: 2rem;
   letter-spacing: 1px;
   margin: auto;
   color: var(--dark_primary_text_color);
}