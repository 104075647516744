.passcode_wrapper{
    width: 35%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.passcode_input_box{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 84%;
    
  height: 50px;
  padding: 5px;

}
.passcode_input_box input{
    height: 80%;
    border: none;
    width: 14px;
    box-shadow: 0px 0px 2px rgb(49, 50, 73);
    color: grey;
    outline: none;
    text-align: center;
    flex: 1;
    transform: skewX(350deg);
}
 .watchButton{
    background-color: var(--dark_primary_text_color);
    height:38px    ;
    color: white;
    display: flex;
    border-radius: 4px;
    align-items: center;
    padding: 0 10px;
    width: 50%;
    justify-content: center;
    outline: none;
    transform: skewX(350deg);
    gap: 10px;
}
.watchButton svg{
    color: white !important;
    fill: white !important;
}
.passcode_text{
    color: rgba(128, 128, 128, 0.709);
    font-size: 12px;
    transform: skewX(350deg);
    letter-spacing: 0.9px;
    text-align: center;
}
.additional_input_form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
@media screen and (min-width:600px) and (max-width:769px) {

    .watchButton {
        width: 70% !important;
    }
    
    .passcode_input_box{
        width: 100% !important;
    }
    .passcode_text{
        font-size: 10px !important;
    }
    .passcode_wrapper{
        width: 40% !important;
    }
}
@media only screen and (min-width: 480px) and (max-width: 600px){

    .passcode_wrapper{
        width: 55%;
    }
    .passcode_text {
        font-size: 9px;
    }
    .watchButton{
       
        height: 38px;
        width: 60%;
        font-size: 13px;
      }
    
}

@media only screen and (max-width: 480px){

    .passcode_wrapper {
        width: 55%;
    }
    .watchButton{
        height: 30px;
        
        font-size: 10px;
       
        width: 50%;
       
    }
    .passcode_input_box {
      
        height: 40px;
    }
    .passcode_text  {
        font-size: 9px;
    }
}