.DecisionBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.team_box{
    display: flex;
    gap: 1rem;
}
.team_box_item{
    width: 100px;
    height: 100px;
    background-color: rgb(242, 238, 238);
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.team_box_item:hover{
    scale: 1.05;
}
.resultBox{
    display: flex;
    gap:1rem;
}

.resultBoxItem{
    width: 100px;
    height: 100px;
    width: 100px;
        background-color: rgb(255, 255, 255);
        border: 2px solid rgb(242, 238, 238);;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.removeIcon{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;

}
.decisionButton{
            background-color: var(--dark_primary_text_color);
            height: 50px;
            padding: 0 1rem;
            ;
            width: fit-content;
            font-size: 14px;
            letter-spacing: 1px;
            color: white;
            border-radius: 5px;
            ;
            font-weight: 500;
            opacity: 0.7;
            letter-spacing: 1px;
            pointer-events: none;
}
.activeButton {
    opacity: 1 !important;
    pointer-events: initial !important;
}
.selected_team_name{
    background-color: var(--primary_color);
    color: white;
    font-weight: bold;

}