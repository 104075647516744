.LiveChatWrapper {
  flex: 8;
  height: 100%;
  backdrop-filter: blur(45px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.live_chat_header {
  background: linear-gradient(
    45deg,
    rgb(174 174 174 / 5%),
    rgb(64 70 221 / 6%),
    rgba(174, 174, 174, 0.21)
  );
  backdrop-filter: blur(45px);
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  gap: 10px;
}
.live_chat_header h2{

  color: rgb(14, 11, 61);
  font-size: 1.3rem;

}
.live_chat_header svg{
  font-size: 1.5rem;
  color: rgb(14, 11, 61);
}
/* h2 {
  font-size: 30px;
  font-weight: bold;
  color: rgb(14, 11, 61);
} */
.live_chat_message_list {
  height: 100vh;
  overflow-y: scroll;
  padding: 1rem 10px;
  border-radius: 20px;
  background: linear-gradient(
    45deg,
    rgb(174 174 174 / 5%),
    rgb(64 70 221 / 6%),
    rgba(174, 174, 174, 0.21)
  );
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

