.DebateRoomWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: auto;
  margin-top: 2rem;
  border-radius: 10px;
  max-width: 1024px;
  background-color:#f4f4f48c;
  position: relative;
  padding: 2rem ; 
  padding-top: 4rem;
  /* filter: blur(1.4px); */
}
.debate_room_top{
  position: relative;
  height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

}
.debate_room_wave{
  position: absolute;
  bottom: 0;
}
.debate_room_top_header {
  display: flex;
  min-height: 120px;
  gap: 20px;
  align-items: center;
  flex-direction: column
  ;
}
.Debate_room_main_text {
  text-transform: uppercase;
  font-size:1.8rem;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  letter-spacing: 2px;

  position: relative;
  width: fit-content;
  font-family: 'Righteous', cursive;
}
.debate_room_top_header_left{
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex: 8;
}
.debate_room_top_header_right{
  text-align: end;
  flex: 4;
}
.intervalBox{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
.intervalBox strong{
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Inter';
}
.main_timing_text{
  
  color: var(--primary_color) !important;
  letter-spacing: 1.2px;
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  /* color:var(--primary_color); */
  /* font-weight: 300; */
  font-weight: 600;

  line-height: 25px;;
  margin-bottom: 0.5rem;
}
.main_text_one {
  color: var(--primary_color);
  position: relative;
  font-size: 21px;
word-break: break-all !important;  
}
.main_text_one::after {
  content: "";
  bottom: 0;
  width: 100%;
  right: 0;

  position: absolute;
  height: 4px;
  border-radius: 10px;
  /* background: linear-gradient(
    45deg,
    rgba(174, 174, 174, 0.11),
    rgba(174, 174, 174, 0.21),
    rgb(64 70 221 / 52%),
    rgb(64 70 221 / 62%)
  ); */
}
.debate_bottom_content{
  padding: 0 ;
}
.round_text {
  color: var(--dark_primary_text_color);
  font-size: 16px;
  height: 30px;

  display: flex;
  align-items: center;
  font-weight: bold;
  background-color: var(--dark_primary_text_color);
  color: white;
  padding: 0 10px;
  border-radius: 4px;
}
.debate_bottom_container {
  min-height: 100vh;
  padding-top: 2rem;
  display: flex;
  gap: 1rem;
}
.leave_btn {
  background-color: rgba(255, 0, 0, 0.397);
  color: white;
  border-radius: 4px;
  padding: 0 10px;
  width: 100px;
  height: 40px;
  margin-left: auto;
}
/* .upcoming_text{
  color:
} */
.startTimeText{
text-transform: capitalize;
}
@media screen  and (min-width:768px)and (max-width:992px){

  .debate_bottom_container{
    flex-direction: column;
  }
  
}
@media screen  and (min-width:600px)and (max-width:768px){

  .debate_bottom_container{
    flex-direction: column;
  }
  
}
@media screen  and (min-width:480px)and (max-width:600px){

  .Debate_room_main_text{
    font-size: 15px;
    text-align:center ;
  }

  .debate_bottom_container{
    flex-direction: column;
  }
  .DebateRoomWrapper{
    padding: 0  1rem;
    padding-top: 4rem;

  }  
}
@media screen   and (max-width:480px){

  .DebateRoomWrapper{
    padding: 0 1rem ;
    padding-top: 4rem;
  }


  .Debate_room_main_text{
    font-size: 12px;
    text-align: center;
  }
  .debate_bottom_container{
    flex-direction: column;
  }
  
}