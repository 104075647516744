.noneJoinedBox {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 10px;
  height: 200px;
  justify-content: center;
}
.noneJoinedBox img {
  width: 40%;
}
.noUsersBox{
  
  background: linear-gradient(45deg, rgb(236 237 252 / 62%), rgb(84 90 250 / 3%));
  backdrop-filter: blur(45px);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.noUsersBox p{
  color: gray;
  font-size: 13px;
  letter-spacing: 1px;
}
