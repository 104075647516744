.top_user{

    display: flex;
    gap: 10px;
    width: 100%;
    height: 60px;
    background-color: aliceblue;
    padding: 0 1rem ;
    justify-content: space-between;
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    align-items: center;
}
.user_country_flag{
    width: 15px;
    padding-top: 5px;

    align-self: flex-start;
}
.top_user:hover{
    scale: 1.02;
}
.main_info{
    gap:10px;
    align-items: center;
    display: flex;
    flex: 10;
    

}
.top_user_avatar{
    width: 35px ;

    height: 35px;
    border-radius: 50%;
}
.points_box {
    display: flex;
    gap: 7px;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: rgb(44, 156, 255);
    flex: 2;
   
}
.points_box img{
    width: 20px;
}
.top_username{
font-size: 13px;
    }
    .top_email{
        font-size: 11px;
        color: rgb(118, 117, 117);
    }

    .own{
        /* background-color: rgba(66, 189, 255, 0.816) !important; */
        border: 2px solid rgb(44, 156, 255) !important;
    }


    @media only screen and (min-width: 769px) and (max-width: 992px) {

        .points_box  {
           
            font-size: 12px;
        }
        /* .main_info {        } */
        .top_user_avatar{
            width: 30px;
            height: 30px;
        }
    }
    @media only screen and (min-width: 600px)  and (max-width: 769px){

        .top_user_avatar {
            width: 25px;
            height: 25px;
        }
        .top_email {
            font-size: 9px;
        }
        .top_username{
            font-size: 12px;
            width: 7rem;
        }
        .top_user{
           
            padding: 0.4rem 1.5rem;
         
        }
      
    }

    @media only screen and (max-width: 480px){
        .main_info{
            flex: 10;
        }
        .points_box {
            flex: 2;
        }
        .points_box {
            align-items: center;
            justify-content: center;
          }
    }