.debateCreated {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
}

.debateCreatedBox {
  display: flex;
  padding: 2rem;
  border-radius: 20px;
  flex-direction: column;
  gap: 2rem;
  height: fit-content;
  margin: auto;
  align-items: center;
  /* background-color: rgba(240, 248, 255, 0.24); */
  width: 95%;
  max-width: 1000px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.debateName {
  color: var(--dark_primary_text_color);
  font-size: 2rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: center;
}
.passcodeBox {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 1rem 1rem;
  height: 60px;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #1e1e1e;
  border: 2px solid rgb(64 70 221);
  color: rgb(64 70 221);
}
.passcodeBox svg {
  cursor: pointer;
  font-size: 2rem;
}
.passcodeBox p {
  /* padding-top: 15px; */
  font-size: 20px;
  letter-spacing: 1px;
  /* width: 120px; */
  /* height: 40px; */
}
/* .passcodeBox */
.debateInfo {
  /* display: flex; */
  font-size: 20px;
  width: 70%;
  text-align: center;
  line-height: 35px;
  letter-spacing: 1px;
}
.navigateIcon {
  font-size: 30px !important;
  cursor: pointer;
}
.clipboardIcon {
  font-size: 25px !important;
}
.joinDebateButton {
  background-color: rgb(64 70 221);
  color: rgb(64 70 221);
  cursor: pointer;

  color: white;
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  gap: 10px;

  letter-spacing: 1px;
  height: 60px;
  align-items: center;
  padding: 0 1rem;
}
