.debateFormatBox{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
     border-radius: 10px;
    
}
.team_format_header{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem  1rem;

}
.team_format_team_selection{
    display: flex;

    flex-direction: column;
    gap: 10px;
    
}
.team_format_team_selection select{
    width: 100%;
    
    background-color: rgba(245, 245, 245, 0.808);
    outline: none;
    border: none;
    font-size: 12px;
    height: 45px;
    color: var(--primary_color);
    border-radius: 5px;

    padding: 0 10px;
}
.team_format_selection_text{
    font-size: 13px;
    text-transform: uppercase   ;
    letter-spacing: 1px;
    color: var(--primary_color);
}
.debate_format_item{
    /* display: flex; */
    /* align-items: center; */
    width: 200%;
      height: 50px;
  border-radius: 4px;
  font-size: 11px;
  padding-left: 8px;
  outline: none;
  letter-spacing: 1px;
  border: none;
     color:black; 
     border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  margin-bottom: 1rem !important;

  flex-direction: row;
  align-items: center;
  gap: 8px;
  border: 1px solid #e2dddd94;
}

.debate_format_item input{
    height: 100% !important;
    font-size: 12px;
    text-align: center;
    background-color: rgb(251 251 251);
    border-radius: 3px;
    color: gray;
    width: 100%;

   
    

}
.team_format_container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
 }
td{
    font-size: 12px;
    
}
.t_data{
    text-align: center;
    /* background-color: red; */
    width: 35%;
}
.t_data_sn{
    width: 25% !important;
    text-align: start !important;
}
.t_data_team_name{
    width: 50%;
    font-size: 12px;
    letter-spacing: 1px;

    text-transform: capitalize;
    text-align: start !important;
}
.t_input_data{
    height: 100%;
}

th{
    font-size: 12px;
    text-align: start;
    text-transform: uppercase;
    letter-spacing: 1px;
    height: 80px;
    color: var(--primary_color);
}
th > div{
    display: flex;
    gap: 5px;
}
th > div svg{
    font-size: 1.2rem;
}.header_text_with_bg p{
    text-transform: uppercase;

}
.debate_format_time_input{

    /* height: 37px !important; */
    /* border: 2px solid rgba(64, 69, 221, 0.527); */

}
.speak_time_input{
    background-color: rgba(230, 230, 230, 0.308);
    text-align: center;
    border-radius: 4px;
    width: 80px;
    
    height: 40px !important;
}