.ProfileCard {
   gap: 3rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 20px;
  width: 80%;
  justify-content: space-between;
  margin: auto;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  
}
.profileCardContainer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
}
.profile_card_top {
  display: flex;
}
.profile_card_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.profile_basic_info_item {
  display: flex;
  gap: 1rem;
}
.profile_card_avatar_wrapper {
  width: 100px;
  height: 100px;
  display: grid;
  place-items: center;
  border: 6px solid var(--primary_color);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.profile_card_avatar_wrapper img {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
}
.profile_card_username {
  font-size: 1.41rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 1rem;
  letter-spacing: 1px;
  color: var(--primary_color);
  text-transform: uppercase;
}
.profile_card_email {
  font-size: 12px;

  letter-spacing: 2px;
}
.profile_card_item_title {
  letter-spacing: 2px;
  /* color: var(--primary_color); */
  margin-bottom: 0.51rem !important;
  font-weight: bold;
  
  font-size: 1.4rem;
  color: var(--dark_gradient_bg);
}
.profile_card_item .other_info_text {
  font-size: 12px;
  margin-bottom: 5px;
  letter-spacing: 1px;
  color: rgb(88, 88, 88);
}
.other_info_text_item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.other_info_text_item img {
  width: 35px;
  height: 35px;
  /* color: var(--primary_color); */
}
.other_info_text_item p{
  font-size: 15px;
  letter-spacing: 1px;
  /* color: red; */
}
.logout_button {
  background-color: red;
  border-radius: 3px;
  font-size: 13px;
  color: white;
  display: flex;
  align-items: center;
  gap:5px;
  height: 40px;
  opacity: 0.6;
  letter-spacing: 1px;
  padding: 0 20px;
  transition: all 0.3s ease;
}
.logout_button svg{
  width: 25px !important;
}
.logout_button:hover {
  opacity: 1;
}
.basic_userinfo_item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}
.live_icon {
  color: rgb(7, 173, 7) !important;
}
.upcoming_icon {
  color: red !important ;
}
.profile_card_item{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.image-row {
  flex-wrap: wrap;
  display: flex;
}
.image-row img{
  margin-top: 10px;
  margin-right: 10px;
}
.circle-image {
  cursor: pointer;
  transition: all .3s ease;
  
  border-radius: 50%;
}
.circle-image:hover{
  scale: 1.05;
}
.profile_card_user_points{
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 8px;

    background-color: rgba(0, 166, 255, 0.504);
    padding: 7px;
    border-radius: 7px;
  }
  .profile_card_user_points p {
    
    font-size: 14px;
    letter-spacing: 1px;
    color: white;
}
.profile_card_user_points img{
  width: 20px;
}
.profile_card_user_country_box{
  display: flex;
  align-items: center ;
  justify-content: start;

  margin: 5px 0;
  gap: 5px;
}
.profileCard_timezoneBox{
  display: flex;
  align-items: center;
  gap: 10px;
}
.profileCard_timezoneBox select{
  height: 30px;
  padding: 0 10px;
  outline: none;
  font-size: 13px;
  letter-spacing: 1px;
  border: 1px solid rgba(128, 128, 128, 0.39);
  border-radius: 4px;

}
.profile_card_user_country_box p ,.profile_card_timeZone{
  font-size: 13px;
  letter-spacing: 1px;
}
.profile_name_user_point_box{
    display: flex;
    gap: 5px;

  }
  .profile_card_item_other_item{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .user_main_detail{
          display: flex;
          gap: 10px;
      flex-direction: column;
  }
  .edit_image_icon{
    /* position: absolute;
    right: -8px;
    bottom: 0; */
  }
  .profile_action_button{
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .change_profile_image_button{
    background-color: blue;

  }
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .ProfileCard {
      gap: 2rem;
  }
    .profile_card_username {
      font-size: 1rem;
  }
  .profile_card_user_points {
    padding: 5px;
}
.profile_card_user_points p {
  font-size: 12px;
}
.profile_card_user_points img {
  width: 17px;
}
.profile_card_item_other_item {
  gap: 7px;
}
.profile_card_item_other_item p{
  font-size: 13px;
}
  }
  @media screen  and (min-width:600px) and (max-width:768px) {
.ProfileCard{
  align-items: center;
}
    .profile_card_top{
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    .profile_basic_info_item{
      flex-direction: column;
      align-items: center;
    }
    
    .profile_card_item_other_item{
      align-items: center;

    }
    .profile_name_user_point_box{
      gap: 15px;
    }
    .user_main_detail{

      align-items: center;
    }
    .basic_userinfo_item{
      align-items: center;
    }
    .other_info_text {
      font:17px;
    }
    .profile_card_email{
      font-size: 18px;

    }
    .profile_card_username{
      font-size: 1.3rem;
    }
    .profile_card_user_country_box p{
      font-size: 16px;
    }
    .other_info_text {
      letter-spacing: 1px;
      font-size: 17px !important;
    }
  }
  
    @media screen  and (min-width:480px) and (max-width:600px) {
.ProfileCard{
  align-items: center;
}
    .profile_card_top{
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    .profile_basic_info_item{
      flex-direction: column;
      align-items: center;
    }
    
    .profile_card_item_other_item{
      align-items: center;

    }
    .profile_name_user_point_box{
      gap: 15px;
    }
    .user_main_detail{

      align-items: center;
    }
    .basic_userinfo_item{
      align-items: center;
    }
    .other_info_text {
      font:17px;
    }
    .profile_card_email{
      font-size: 18px;

    }
    .profile_card_username{
      font-size: 1.2rem !important;
    }
    .profile_card_user_country_box p{
      font-size: 16px;
    }
      .other_info_text {
        letter-spacing: 1px;
      font-size: 17px !important;
    }
  }

    @media screen  and (max-width:480px) {
.ProfileCard{
  align-items: center;
  width: 95%;
}
    .profile_card_top{
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    .profile_basic_info_item{
      flex-direction: column;
      align-items: center;
    }
    
    .profile_card_item_other_item{
      align-items: center;

    }
    .profile_name_user_point_box{
      gap: 3px;
    }
    .profile_card_user_points p {
      font-size: 12px;
  }
  .profile_card_user_points img {
    width: 17px;
}
    .user_main_detail{

      align-items: center;
    }
    .basic_userinfo_item{
      align-items: center;
    }
    .other_info_text {
      font:17px;
    }
    .profile_card_email{
      font-size: 14px;

    }
    .profile_card_username{
      font-size: 0.9rem !important;
      letter-spacing: 2px !important;
    }
    .profile_card_user_country_box p{
      font-size: 14px;
    }
      .other_info_text {
        letter-spacing: 1px;
      font-size: 13px !important;
    }
    .profile_card_item_title {
      font-size: 0.8rem;
  }
  }