.win_logo {
  opacity: 0.7;
}
.debate_topic {
  font-size: 14px;
  opacity: 0.7;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white !important;
}
.modelContent {
  max-width: 550px;
  width: 90%;
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding-bottom: 1rem;
  align-items: center;
  background-color: rgba(73, 73, 73, 0.233);
  border-radius: 20px;
  padding-top: 0.5rem;
  position: relative;
  z-index: 3 !important;
  backdrop-filter: blur(5px);
  padding: 2rem 1rem;
}
.top_winning_team {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 10px;
}
.winning_team_text {
  font-weight: bold;
  font-family: "Ysabeau", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  
  opacity: 0.7;
  font-size: 1.1rem;
  text-align: center;
}

.vote_count_box {
  width: 100%;
  align-items: center;
  gap: 1rem;
  display: flex;
  justify-content: center;
}
.vote_button_count {
  background-color: var(--primary_color);
  height: 35px;

  width: fit-content;
  padding: 0 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  opacity: 0.7;
  scale: 0.9;
}
.winning_team_vote {
  opacity: 0.7 !important;
  background-color: #f9b938c4;
  scale: 0.9 !important;
}
.winning_team_vote svg {
}
.vote_button_count p {
  font-size: 13px;
  letter-spacing: 1px;
}

.robo_1,
.robo_2 {
  position: absolute;

  z-index: -1 !important;
}
.robo_1 {
  right: -3rem;

  width: 20%;
}
.robo_2 {
  width: 20%;
  left: -3rem;
}
.nextButton {
  background-color: #7c38f9;
  width: fit-content;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 12px;
  height: 40px !important;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: row-reverse;
  color: rgb(238, 233, 233);
  opacity: 0.7;
  transition: all 0.3s;
}
.nextButton svg {
  font-size: 18px !important;
  fill: white !important;
}
.mysteryButtonBox {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
}
