.NoLiveDebateWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin-top: 6rem;
  gap: 2rem;
}
.no_live_debate_dino_img {
  width: 180px;
  border-radius: 50%;
  height: 180px;
}
.no_live_debate_text {
  font-family: "Inter", sans-serif !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  
  font-size: 1.3rem;

  font-weight: bolder;
  color: var(--dark_primary_text_color);
}
.check_upcoming_videos {
  background-color: var(--primary_color);
  height: 45px;
  padding: 0 20px;
  border-radius: 5px;
  font-size: 12px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1.5px;
}
._noLive_debate_bottom_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.check_upcoming_videos:hover {
  background-color: var(--primary_dark_color);
}
