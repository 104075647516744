.debate_page{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    padding: 2rem;
}
.debate_page_header{

}
.debate_page_tab{
    display: flex;
    border: 3px solid var(--dark_primary_text_color);
    border-radius: 4px;
    padding: 5px;
}
.debate_page_tab button{
    padding:  0  1rem;
    height: 45px;
    font-size: 14px;
    letter-spacing: 1px;
    border-radius: 4px;
    color: var(--dark_primary_text_color);
    
}
.debate_page_header h1{
    font-size: 2rem;
    font-weight: bold;
}
.active_debate_tab{
    background: black !important;

    color: white !important;
}