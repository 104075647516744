.confirmation_page{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirmation_page_box{
      background-color: rgba(232, 235, 238, 0.673);
      width: 90%; 
      max-width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    align-items: center;
    padding: 1rem;
    border-radius: 15px;
}
.confirmation_page_box >img{
    width: 200px;
}
.confirm_button{
    background: var(--primary_color);
    color: white;
    height: 40px;
    border-radius: 3px;
    letter-spacing: 1px;
    padding:  0 10px;
    margin: 1rem 0 ;
    font-size: 14px;
}
.confirm_desc{
    color: rgb(102, 101, 101);
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.81px;
    line-height: 30px;
}