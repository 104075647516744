

.calendly_coach_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    width: 100%;
    padding: 2rem;
}

.coach_name_text{
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--dark_primary_text_color);

}
.coach_desc_text{
    letter-spacing: 1px;
    font-size: 12px;
    text-align: justify;
    line-height: 20px;
}
.coach_info{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 2rem;
}
.coach_info .avatar_img{
    width: 35px;
    height: 35px;
    object-fit: cover;
border-radius: 50%;
}
.flag_img{
    /* align-self: flex-start; */
    width: 20px;
}
.formatedParagph{
    text-align: start !important;
    align-self: flex-start !important;
}