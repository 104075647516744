.SearchParticipantsListWrapper {
  background:#ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 300px;
  overflow: scroll;

}
/* .participants_list {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
  text-align: center;
} */
