.deleteBtn{

}
.action_btns{
    gap: 8px;
    display: flex;
}
.action_btns svg{
    font-size: 1.2rem;
    cursor: pointer;

}

.deleteIcon{
    fill: red !important    ;
}
.editIcon{
    fill: green;
}